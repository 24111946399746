import React from 'react'
import {graphql} from 'gatsby'
import {PrismicRichText} from "@prismicio/react";
import {AnchorLink} from "../../components/AnchorLink";

// assets
import {getFontSize} from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";
import { MembershipListItem } from "./membershipListItems";

// constants
import {COLOR} from "../../constants/colors";



export const Membership = ({slice}) => {
  const {width} = useWindowSize()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showSubTitle = slice.primary.show_subtitle
  const showTitle = slice.primary.show_title

  // Text
  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor = slice.primary.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '24'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '48'
  const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraphs = slice.items
  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  // Section
  const section = {
    bgColor: slice.primary.section_background_color || COLOR.WHITE,
    spacingTop: slice.primary.section_spacing_top || '0',
    spacingBottom: slice.primary.section_spacing_bottom || '0',
  }

  const sectionStyles = {
    backgroundColor: section.bgColor,
    marginTop: `${section.spacingTop}px`,
    marginBottom: `${section.spacingBottom}px`
  }

  return (
      <section className="membership" style={sectionStyles}
          id={`membership-${sectionId}`}
      >
        { anchorLink && <AnchorLink link={anchorLink}/> }

        <div className='membership__text-wrap'>

          {showTitle && <h3 className='membership__title'>
            {title.map((row, index) => {
              return <span key={index} style={titleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>
            })
            }
          </h3>}

          {showSubTitle && <h4 className='membership__subtitle'>
            {subTitle.map((row, index) => {
              return <span key={index} style={subTitleStyles}>{row.text}<br/></span>
            })
            }
          </h4>}

          {paragraphs.length !== 0 && <div className='membership__cards'>
            {paragraphs.map((item, index) => {
              return <MembershipListItem card={item} index={index} key={index}/>
            })}
          </div>}

        </div>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyMembership on PrismicGenericPageDataBodyMembership  {
    id
    primary {
    section_background_color
      section_id{
        text
      }
      anchor_link{
        text
      }
      show_title
      show_subtitle
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
    }
    items {
      title_icon {
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
    }
  }
`;