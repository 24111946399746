import React, { useState } from 'react'
import { graphql } from "gatsby";

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// components
import { ImageSlide, VideoSlide } from "./heroCarouselSlides";
import { AnchorLink } from "../../components/AnchorLink";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



export const Hero = ({ slice, context }) => {
  // console.log(slice, context)
  const [swiper, setSwiper] = useState(null);
  const carouselSlides = slice.items


  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  const handleMouseEnter = () => {
    swiper.autoplay.stop();
  };

  const handleMouseLeave = () => {
    swiper.autoplay.start();
  };


  const navigation = {
    nextEl: '.hero-section__carousel-arrow--next',
    prevEl: '.hero-section__carousel-arrow--prev'
  }

  return (
      <section onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id={`hero-${sectionId}`}>
        { anchorLink && <AnchorLink link={anchorLink}/> }

        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            navigation={navigation}
            pagination={{ clickable: true }}
            initialSlide={0}
            speed={500}
            slidesPerView={1}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true
            }}
            loop={true}
            onSwiper={setSwiper}
            className='hero-carousel'
        >
          {
            carouselSlides.map((item, index) => {
              // console.log('carousel item', item)
              return (
                  <SwiperSlide key={`${item.slide_type}-${index}`} className='position-relative'>
                    { !item.content_type &&  <VideoSlide item={item} context={context}/> }
                    { item.content_type && <ImageSlide item={item}/> }
                  </SwiperSlide>
              )
            })
          }
          <button className="hero-section__carousel-arrow hero-section__carousel-arrow--prev">
            <span className="visually-hidden">Prev</span>
            <FontAwesomeIcon icon={faChevronLeft} className="fa-solid hero-carousel-icon" size="2xl" />
          </button>
          <button className="hero-section__carousel-arrow hero-section__carousel-arrow--next">
            <span className="visually-hidden">Next</span>
            <FontAwesomeIcon icon={faChevronRight} className="fa-solid hero-carousel-icon" size="2xl" />
          </button>
        </Swiper>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyHero on PrismicGenericPageDataBodyHero  {
    id
    primary{
      section_id{
        text
      }
      anchor_link{
        text
      }
    }
  
    items {
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      cta_link {
        url
      }
      github_video_filename_mobile
      github_video_filename_desktop
      content_type
      reverse_content
      show_button
      show_title
      show_subtitle
      show_paragraph
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      subtitle {
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      paragraph {
        text
        richText
      }
      text_alignment_desktop
      text_alignment_mobile
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
    }
  }
`;




