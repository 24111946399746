// core
import React, { useEffect } from 'react'

import { faXmarkCircle, faWindowClose } from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function Modal({ closeModal, children }) {
  useEffect(() => {
    document.querySelector('body').classList.add('modal-open')
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      document.querySelector('body').classList.remove('modal-open')
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = event => {
    if (event.code === 'Escape') {
      closeModal();
    }
  };

  const handleOnOverlayClick = event => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="fs-modal">
      <div className="fs-modal__modal-overlay" onClick={handleOnOverlayClick}>
        <div className="fs-modal__modal-content">
          <div className="fs-modal__modal-body">{children}</div>
          <div className="fs-modal__modal-footer">
            <button className="fs-modal__modal-button" type="button" onClick={() => closeModal()}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
