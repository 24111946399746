import React, { useEffect, useRef } from "react";
import { PrismicRichText } from "@prismicio/react";
import { useLocation } from "@reach/router";

// components
import CustomLink from "../../../components/CustomLink";

// assets and utils
import useWindowSize from "../../../hooks/useWindowSize";
import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../../assets/js";
import { useConsent } from "../../../components/ConsentContext";

// constants
import { COLOR } from "../../../constants/colors";
import * as mobileVideo from "../../../../static/fallback-videos/mobile";
import * as desktopVideo from "../../../../static/fallback-videos/desktop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

// data-layer
import { setDownloadClickedEvent } from "../../../assets/js/data-layer";

export const VideoSlide = ({ item, context }) => {
  // console.log("VideoSlide",item)
  const { data } = context

  const location = useLocation()
  const consentAccepted = useConsent()

  const { width } = useWindowSize()
  const playerRef = useRef()

  const CTALink = item.cta_link.url
  const showButton = item.show_button
  const showSubTitle = item.show_subtitle
  const showTitle = item.show_title
  const showParagraph = item.show_paragraph
  const showContentBlock = showSubTitle || showTitle || showParagraph

  const reverseButtonClass = item.reverse_content ? "hero-carousel__cta-button--right" : "hero-carousel__cta-button--left"
  const reverseContentClass = item.reverse_content ? "hero-carousel__content--right" : "hero-carousel__content--left"

  // Links
  const isDownloadLink = item.cta_download_button
  const isTargetBlank = item.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const subTitle = item.subtitle?.richText || []
  const subTitleTextColor = item.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = item.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = item.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)


  const title = item.title?.richText || []
  const titleTextColor =item.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = item.title_font_size_desktop || '82'
  const titleTextFontSizeMobile = item.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)


  const textAlignmentMobile = item.text_alignment_mobile || 'center'
  const textAlignmentDesktop = item.text_alignment_desktop || 'center'
  const paragraph = item.paragraph?.richText || []
  const paragraphTextColor = item.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = item.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = item.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const textAlignment = width < 768 ? textAlignmentMobile:textAlignmentDesktop
  // text content styles
  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize

  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  // CTA Button
  const ctaButton = {
    text: item.cta_button_text?.text || '',
  }

  const ctaDownloadButtonFileName = item.cta_download_file_name || ''
  const ctaButtonDownloadLink = item.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: item.cta_button_text_color,
    bg: item.cta_button_bg_color,
    bgT: item.cta_button_bg_color_transparency,
    border: item.cta_button_border_color,
    colorHover: item.cta_button_text_color_hover,
    bgHover: item.cta_button_bg_color_hover,
    bgHoverT: item.cta_button_bg_color_hover_transparency,
    borderHover: item.cta_button_border_color_hover,
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)


  // video
  const githubVideoFilename = width < 768 ? item?.github_video_filename_mobile : item?.github_video_filename_desktop;
  const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
  const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

  useEffect(() => {
    if (!item && !playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])

  const alighmentStyle = {
    maxWidth: textAlignment =="center"?'':"700px",
    margin:textAlignment == "center"?'auto':'',
    marginLeft:textAlignment == "right"?'auto':'',
    marginRight:textAlignment == "left"?'auto':''
  }
  return (
      <>
        <CustomLink to={CTALink} >
          <video className='hero-carousel__video'
                 ref={playerRef}
                 src={video}
                 width="100%"
                 height="100%"
                 playsInline
                 autoPlay
                 muted
                 loop
                 poster="" />
        </CustomLink>
        <div className = {"hero-carousel-content"} style={{"textAlign":textAlignment}}>
          <div style={alighmentStyle}>
            { showContentBlock && <>
              {showTitle && <h1 style = {{lineHeight:width < 768?"":"65px"}}>
                {title.map((row) => {
                  return <span key={row.text} style={titleStyles}>
                    <PrismicRichText field={[row]}/><br/>
                  </span>
                })
                }
              </h1>}

              {showSubTitle && <h2 style = {{lineHeight:width < 768?"":"65px"}}>
                {subTitle.map((row) => {
                  return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
                })
                }
              </h2>}

              {showParagraph && <p>
                {paragraph.map((row) => {
                  return <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>
                })
                }
              </p>}
            </> }

            {showButton && <>

              {!isDownloadLink && <a href={CTALink} title={ctaButton.text}
                                    className={`hero-carousel-content--button`}
                                    style={ctaButtonStyle} target={target}>
                {ctaButton.text}
                {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
              </a>}

              {isDownloadLink &&
                  <button title={ctaButton.text} className={`hero-carousel-content--button`}
                          style={ctaButtonStyle}
                          onClick={() => {
                            downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                            // 'download_clicked' data layer event
                            setDownloadClickedEvent({
                              fileName: ctaDownloadButtonFileName,
                              clickUrl: ctaButtonDownloadLink,
                              clickText: ctaButton.text,
                              pageUrl: location.href
                            }, consentAccepted)
                          }}
                  >
                    {ctaButton.text}
                    <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                  </button>}
            </>}
          </div>
        </div>
      </>
  )
}