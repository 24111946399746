import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser'
import { Link } from 'gatsby'
const AccordionCard = ({ items,handleOpenModal }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const renderPrismicRichText = (richText) => {
    return (
      <div>
        {richText.map((span, index) => {
          switch (span.type) {
            case 'heading1':
              return <h1 key={index}> {renderSpans(span)}</h1>;
            case 'heading2':
              return <h2 key={index}> {renderSpans(span)}</h2>;
            case 'heading3':
              return <h3 key={index}> {renderSpans(span)}</h3>;
            case 'heading4':
              return <h4 key={index}> {renderSpans(span)}</h4>;
            case 'heading5':
              return <h5 key={index}> {renderSpans(span)}</h5>;
            case 'heading6':
               return (
                <h6 key={index}>
                  {renderSpans(span)}
                </h6>
              );
            case  'paragraph':
              return (
                <p key={index}>
                  {renderSpans(span)}
                </p>
              );
            case 'hyperlink':
              return <a key={index} href={span.data.url}> {renderSpans(span)}</a>;
            case 'strong':
              return <strong key={index}> {renderSpans(span)}</strong>;
            case 'em':
              return <em key={index}> {renderSpans(span)}</em>;
            case 'list-item':
              return <li key={index}> {renderSpans(span)}</li>;
            case 'o-list-item':
              return <li key={index}> {renderSpans(span)}</li>;
            case 'image':
              if(span.linkTo?.uid){
                return  (<Link
                          to= {`/${span.linkTo?.uid}`}
                          target="_blank">
                            <img key={index} src={span.url} alt={span.alt || ''} />;
                        </Link>)
              }else if(span.linkTo?.url){
                return  (<a href={span.linkTo?.url} target="_blank">
                            <img key={index} src={span.url} alt={span.alt || ''} />;
                        </a>)
              }else{
                return <img key={index} src={span.url} alt={span.alt || ''} />;
              }
             
              case 'preformatted':
                return (<div>{parse(span.text)}</div>);
            default:
              return null;
          }
        })}
      </div>
    );
  };

 const renderSpans = (item) => {
  const spans = item.spans
  const text = item.text
  if (!spans || spans.length === 0) {
    return text; // Render plain text if there are no spans
  }
  const span = spans[0];
  let lastIndex = 0;
  let renderedText = [];

  // Add plain text before the span
  if (span.start > lastIndex) {
    renderedText.push(text.slice(lastIndex, span.start));
  }

  // Render based on span type
  switch (span.type) {
    case 'hyperlink':
      if (span.data?.link_type?.toLowerCase() === 'media') {
        // Handle media link type
        const modalImgSrc = span.data.url || '';
        const modalImgWidth = span.data.width || '';
        const modalImgHeight = span.data.height || '';
        const modalImgTag = <img src={modalImgSrc} width={modalImgWidth} height={modalImgHeight} alt="" />;
        renderedText.push(
          <a key={0} href="#" onClick={(event) => handleOpenModal(event, modalImgTag)}>
            {text.slice(span.start, span.end)}
          </a>
        );
      } else {
        // Default handling for regular links
        renderedText.push(
          <a key={0} href={span.data?.url ? span.data.url : span.data?.uid ? span.data.uid : '/'}>
            {text.slice(span.start, span.end)}
          </a>
        );
      }
      break;
    case 'strong':
      renderedText.push(
        <strong key={0}>
          {text.slice(span.start, span.end)}
        </strong>
      );
      break;
    default:
      // Render plain text for unknown span types
      renderedText.push(text.slice(span.start, span.end));
      break;
  }

  // Add any remaining plain text after the span
  if (span.end < text.length) {
    renderedText.push(text.slice(span.end));
  }

  // Join all parts into a single JSX fragment
  return <>{renderedText}</>;
};
  return (
    <div className="accordion custom-accordion">
      {items.map((item, index) => (

        <div className={`accordion-item ${openIndex === index ? 'active' : ''}`} key={index}>
          <div className= {`accordion-header ${openIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
            {renderPrismicRichText(item.question.richText)}
            <button
              className="accordion-toggle"
              onClick={(e) => {
                e.stopPropagation();
                toggleAccordion(index);
              }}
            >
               <FontAwesomeIcon icon={openIndex == index ? faClose : faPlus} aria-hidden="false" size="md" />
            </button>
          </div>
          {openIndex === index && (
            <div className={`accordion-content ${openIndex === index ? 'active' : ''}`}>
             { renderPrismicRichText(item.answer.richText)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AccordionCard;
