import React from 'react'
import { graphql } from 'gatsby'

// assets
import useWindowSize from '../../hooks/useWindowSize';
import Fade from 'react-reveal/Fade';
import {AnchorLink} from "../../components/AnchorLink";
import {COLOR} from "../../constants/colors";
export const PolaroidPhotoGallery = ({ slice }) => {
  // console.log("PolaroidPhotoGallery Slice", slice)
  const { width } = useWindowSize()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''
  const section = {
    bgColor: slice.primary.section_background_color || COLOR.WHITE
  }
  const sectionStyles = {
    backgroundColor: section.bgColor
  }

  return (
    <section className="polaroid-photo-gallery" 
      style={sectionStyles}
      id={`polaroid-photo-gallery-${sectionId}`}
    >
      { anchorLink && <AnchorLink link={anchorLink}/> }

      <div className='polaroid-photo-gallery__inner'>
        { Object.values(slice.primary).map((item, index) => {
            if(item?.thumbnails){
              return (
                <Fade bottom duration={1000} delay={index * 150} distance={"20px"} key={index}>
                  <div className={`polaroid-photo-gallery__item polaroid-photo-gallery__item--0${index + 1}`}>
                    <div className="polaroid-photo-gallery__card">
                      <img src={width > 991 ? item?.url : item.thumbnails?.mobile?.url} alt={width > 991 ? item.alt : item.thumbnails?.mobile.alt} />
                    </div>
                  </div>
                </Fade>
              )
            }
          })
        }
      </div>
    </section>
  );
}

export const fragment = graphql`
  fragment PageDataBodyPolaroidPhotoGallery on PrismicGenericPageDataBodyPolaroidPhotoGallery  {
    id
    primary {
      section_id{
        text
      }
      section_background_color
      anchor_link{
        text
      }
      image_01 {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      image_02 {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      image_03 {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      image_04 {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      image_05 {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
    }
  }
`;