// core
import React,{useState,useEffect,useRef} from 'react';

function GitHubVideoModal({closeModal,modalDetails,lang}) {

    useEffect(() => {
        document.querySelector('body').classList.add('modal-open')
        window.addEventListener('keydown',handleKeyDown);
        return () => {
            document.querySelector('body').classList.remove('modal-open')
            window.removeEventListener('keydown',handleKeyDown);
        };
    });

    const handleKeyDown=event => {
        if(event.code==='Escape') {
            closeModal();
        }
    };

    const handleOnOverlayClick=event => {
        if(event.target===event.currentTarget) {
            closeModal();
        }
    };

    const [dimensions,setDimensions]=useState({width: '60vw',height: 'auto'});
    const videoRef=useRef(null);


    useEffect(() => {
        const updateDimensions=() => {
            if(videoRef.current) {
                const {videoWidth,videoHeight}=videoRef.current;
                if(videoWidth&&videoHeight) {
                    const aspectRatio=videoWidth/videoHeight;
                    const newWidth=window.innerWidth*0.8; // 80% of the window width
                    const newHeight=newWidth/aspectRatio;
                    setDimensions({width: `${newWidth}px`,height: `${newHeight}px`});
                }
            }
        };

        window.addEventListener('resize',updateDimensions);
        updateDimensions(); // Initial call to set dimensions

        return () => {
            window.removeEventListener('resize',updateDimensions);
        };
    },[modalDetails]);
    return (<div className='fullscreen-modal'>
        <div className='modal video-popup' onClick={handleOnOverlayClick}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-close" data-dismiss="modal" onClick={closeModal}>
                            <span className="modal-close__text">Close</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="modal-close__icon">
                                <path d="M0 0h24v24H0V0z" fill="none"></path>
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="embed-responsive-item embed-responsive-16by9">
                            <video
                                id="video"
                                ref={videoRef}
                                src={modalDetails}
                                type="video/webm"
                                className="w-100"
                                style={{width: dimensions.width,height: dimensions.height,maxHeight: "85vh"}}
                                controls
                                autoplay="autoplay"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default GitHubVideoModal;
